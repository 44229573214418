import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/gummy/gummycontent.js";
import GummyFaq from "../sections/gummy/gummyfaq.js";
import Head from "../components/head";
import Testimonial from "../sections/index/indexreviews.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/gums.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PopupForm from "../components/PopupForm/PopupForm"

const Gummy = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
    <Head title="Gummy Smile Treatment UK" image="/images/dermamina-home.jpg"  description="Experts in Gummy Smile treatments London instant results from £220" keywords="gummy smile treatment, gummy smile correction, fix gummy smile, gummy smile surgery, gummy smile reduction, treatment for gummy smile, reduce gummy smile, correct gummy smile, gummy smile dentist, gummy smile laser treatment"/>
<PageWrapper headerDark>
  <PopupForm
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  />
<HeroComponent
    itext='<span style="color: #1a1a1a;">Gummy </span> <span style="color: #00aec7;">Smile</span>'
    secondText="Be in love with your smile"
    videoAlt="Gummy Smile"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="1 treatment"
  bestalt="best Gummy Smile"
  resultstext="3-4 months"
  resultsalt="Gummy Smile results"
  costdata="£220"
  costalt="Gummy Smile Cost"
  timedata="10 Minutes"
  timealt="Gummy Smile Duration Time"
  workdata="Immediately"
  workalt="Gummy Smile downtime work"
  paindata="None / Mild"
  painalt="Gummy Smile Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
<Testimonial />
<Content
  setIsModalOpen={setIsModalOpen}
/>
<GummyFaq setIsModalOpen={setIsModalOpen}/>

<Clinic /> 
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default Gummy;
